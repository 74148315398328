import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';

const PDFViewer = ({ selectedPDF }) => {
  const { pdf, url = "" } = selectedPDF || {};
  const { description = "", name = "", totalPages = 0 } = pdf || {};


  return (

    <iframe
      src={url}
      title="PDF Viewer"
      style={{
        width: '100vw',
        height: '100vh',
        border: 'none'
      }}
    />

  );
};

export default PDFViewer;