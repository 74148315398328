import { Link } from 'react-router-dom';
import styles from '../index.module.sass';

const CourseRow = ({ element }) => {
  const determineLinkPath = (element) => {
    // List of Courses to check in the element name
    const keyCourses = [
      'Examen',
      'Exámenes',
      'EXAMEN',
      'Laboratorio',
      'Santo-Repaso-Domingo-M',
      'Santo-Repaso-Domingo-M',
      'Santo-Repaso-Viernes-T',
      'Santo-Repaso-Miercoles-T',
      'Santo-Repaso-Miercoles-M',
      'Santo-Repaso-Viernes-M',
      'Santo-Repaso-Lunes-T',
      'Santo-Repaso-Domingo-T',
      'Santo-Repaso-Lunes-M',
      'Santo-Repaso-Sabado-M',
      'Santo-Repaso-Sabado-T',
      'Examen simulacro 1 (Ingeniería)',
      'Examen simulacro 2 (Ingeniería)',
      'Examen simulacro 3 (Ingeniería)',
      'Examen simulacro 1 (Sociales)',
      'Examen simulacro 2 (Sociales)',
      'Examen simulacro 3 (Sociales)',
      'Examen simulacro 1 (Medicina)',
      'Examen simulacro 2 (Medicina)',
      'Examen simulacro 3 (Medicina)',
      'Examen UNAM 1',
      'Examen UNAM 2',
      'Examen UNAM 3',
      '1er Examen Simulacro COMIPEMS (presencial)',
      '2do Examen Simulacro COMIPEMS (presencial)',
      '3er Examen Simulacro COMIPEMS (presencial)'
    ];

    // Check if the element name includes any of the following keywords
    const includesKeyword = keyCourses.some((course) => element?.name.includes(course));

    return `${includesKeyword ? /exams-fast-register/ : /register-course-group/}${element._id}`;
  };

  return (
    <div
      className={`${styles.course_row} ${
        element.active ? styles.course_done : styles.course_fail
      }`}>
      <p>
        <Link to={determineLinkPath(element) || '/register'}>{element.name}</Link>
      </p>
    </div>
  );
};

export default CourseRow;
