/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import { Link } from 'react-router-dom';
import VideoPreview from '../video-preview';
import Icon from '../icons';
import EmptyComponent from '../../components/Empty/EmptyComponent';
import Spinner from '../../components/spinner';
import { context } from '../../context';
import styles from './sidebarPlaylist.module.scss';
import PDFViewer from 'components/PDF/PDFViewer';


const SidebarPlaylist = ({ history, actualVideoInfo }) => {
    const { state, actions } = useContext(context);
    const [loading, setLoading] = useState(false);
    const [openModalPDF, setOpenModalPDF] = useState(false);

    useEffect(() => {
        actions.changeSideBar(true)
    }, [])

    const backChapter = () => {
        setLoading(true)
        let result = actions.getPlaylistById(state.playlist.parent)
        result.then(
            () => {
                history.push({
                    pathname: `/playlist/${state.playlist.parent}`,
                })
                setLoading(false)
            }
        )
        if (state.video !== null) {
            state.video = null
        }
    }

    const backCourseChapter = () => {
        setLoading(true)
        let result = actions.getPlaylistByCourseId(state.playlist.courseInfo._id)
        result.then(
            () => {
                history.push({
                    pathname: `/coursePlaylist/${state.playlist.courseInfo._id}`,
                })
                setLoading(false)
            }
        )
        if (state.video !== null) {
            state.video = null
        }
    }
    return (
        <div className="sidebar__content">
            <aside className={styles.aside}>
                <div className={styles.button_container}>
                    <div className={state.playlist !== null && (state.playlist.parent !== undefined || state.playlist.courseInfo !== undefined) ? "" : styles.hide_back}>
                        <button href="#" className={styles.back_page}
                            onClick={() => {
                                if (state.playlist.courseInfo !== undefined) {
                                    backCourseChapter()
                                } else {
                                    backChapter()
                                }
                            }}
                        >
                            <Icon name="left" className={styles.arrow_left} />
                            <span>Regresar</span>
                        </button>
                    </div>
                    <div>
                        <Link to="/home">
                            <button className={styles.back_page}>
                                <Icon name="home" className={styles.arrow_left} />
                                <span className="ml-1">Inicio</span>
                            </button>
                        </Link>
                    </div>
                </div>
                {loading || state.playlist === null ? state.error === null ? <Spinner /> : <EmptyComponent message={state.error ? state.error.message : "Ocurrio un error"} /> :
                    <>
                        <p className={styles.course_name}>{state.playlist.name}</p>
                        <Scrollbar className={`sidebar__scroll scroll ${styles.scroll_playlist}`}>
                            <div>
                                <div>
                                    <div className={styles.container_playlist_files}>
                                        {state.playlist !== undefined && state.playlist.contentPlaylist.length > 0 ?
                                            state.playlist.contentPlaylist.map((data) => (
                                                <VideoPreview
                                                    key={data._id}
                                                    data={data}
                                                    actualVideoInfo={actualVideoInfo}
                                                    setLoading={setLoading}
                                                    isSelected={(state.video && state.video._id && state.video._id === data._id) || (state.quiz && state.quiz._id && state.quiz._id === data._id)}
                                                    history={history}
                                                />
                                            )) : <EmptyComponent message="No hay material agregado" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Scrollbar>
                    </>
                }
            </aside>
        </div>
    )
}

export default SidebarPlaylist;
