import React, { useReducer, createContext } from 'react';
import reducers from './reducers';
import actionCreators from './actions';

const initialState = {
  days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
  isLoggedIn: !!localStorage['student'],
  sideBarOpen: false,
  student: {
    address: '',
    financial: {
      payments: {
        total: 0,
        discounts: 0
      }
    },
    attendance: {
      absences: 0,
      total: 0
    },
    picture: localStorage['fbPicture'] || ''
  },
  courses: [],
  course: null,
  loading: false,
  video: null,
  quiz: null,
  pdf: null,
  latsQuizActive: null,
  quizAttempts: null,
  answeredExam: null,
  error: null,
  schoolData: {
    courses: []
  },
  attendance: [],
  levels: [],
  quizAttemptActualQuestion: 0,
  availableCourses: [],
  availableGroups: [],
  selectedCourse: {},
  playlist: null,
  financial: null,
  paymentCourseInfo: null,
};

const reducer = (state = initialState, { type, payload }) => {
  return reducers[(payload.success && type) || 'ERROR'](state, payload);
};

export const context = createContext(initialState);

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = actionCreators(state, dispatch);
  return <context.Provider value={{ state, actions }}>{children}</context.Provider>;
};

export default Context;
