import React from 'react';

const PDFIcon = ({ name = 'Document', description = "", onClickDownloadPDF }) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '15px',
    cursor: 'pointer',
  };

  const svgContainerStyle = {
    minWidth: '100px',
    minHeight: '180px',
    width: '100px',
    height: '180px',
    flexShrink: 0
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingTop: '10px'
  };

  const fileNameStyle = {
    marginTop: "10px",
    fontSize: '20px',
    fontWeight: '500',
    color: '#333',
  };
  const fileDescriptionStyle = {
    marginTop: "10px",
    fontSize: '15px',
    fontWeight: '500',
    color: '#333',
  };

  const buttonStyle = {
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s'
  };

  return (
    <div style={containerStyle} onClick={onClickDownloadPDF}>
      <div style={svgContainerStyle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 120"
          style={{ width: '100%', height: '100%' }}
          preserveAspectRatio="xMidYMid meet"
        >
          {/* Document background */}
          <path
            d="M20 0 H70 L90 20 V110 A10 10 0 0 1 80 120 H20 A10 10 0 0 1 10 110 V10 A10 10 0 0 1 20 0Z"
            fill="#ffffff"
            stroke="#e74c3c"
            strokeWidth="2"
          />

          {/* Folded corner */}
          <path
            d="M70 0 L70 20 H90 L70 0"
            fill="#e74c3c"
          />

          {/* PDF text */}
          <text
            x="50"
            y="60"
            fontFamily="Arial, sans-serif"
            fontSize="24"
            fontWeight="bold"
            fill="#e74c3c"
            textAnchor="middle"
          >
            PDF
          </text>

          {/* Decorative lines */}
          <line
            x1="30"
            y1="95"
            x2="70"
            y2="95"
            stroke="#e74c3c"
            strokeWidth="2"
          />
          <line
            x1="35"
            y1="102"
            x2="65"
            y2="102"
            stroke="#e74c3c"
            strokeWidth="2"
          />
        </svg>
      </div>

      <div style={contentStyle}>
        <span style={fileNameStyle}>{name}</span>
        <span style={fileDescriptionStyle}>{description}</span>
      </div>
    </div>
  );
};

export default PDFIcon;