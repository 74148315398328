/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { context } from '../../context';
import VideoPlay from '../../pages/video-detail/videoPlay';
import Quiz from '../../components/quizInfo/index';
import Spinner from '../../components/spinner';
import styles from './index.module.sass';
import PDFViewer from 'components/PDF/PDFViewer';


const Playlist = (props) => {

  const { state, actions } = useContext(context);
  // const [showQuiz, setShowQuiz] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    let result
    if (props.match.params.playlistId !== undefined) {
      result = actions.getPlaylistById(props.match.params.playlistId)
    } else {
      result = actions.getPlaylistByCourseId(props.match.params.courseId)
    }
    result.then((result) => {
      if (!result.success) {
        setError(result.error)
      } else {
        setError(undefined)
      }
    }
    )
  }, []);

  useEffect(() => {
    const type = props.match.params.typeSelected
    const idElement = props.match.params.idSelected
    if (props.match.params.typeSelected !== undefined) {
      if (type === "quiz") {
        getQuizInfo(idElement)
      } else if (type === "video") {
        setDataVideo(idElement)
      } else {
        state.quiz = null
        state.video = null
      }
    } else {
      state.quiz = null
      state.video = null
    }
  }, [props.match.params.playlistId, props.match.params.typeSelected, props.match.params.idSelected])

  const setDataVideo = (id) => {
    actions.getVideoById(id)
  }

  const getQuizInfo = (id) => {
    actions.getQuizInfoById(id)
    actions.getStudentQuizAttemps(id);
  }

  return (
    !!state.playlist ? (
      <>
        <div className={styles.playlist_container}>
          {
            (state.video === null && state.quiz === null && state.pdf === null ? "Selecciona un video o evaluación" :
              state.video != null ?
                <VideoPlay /> : state.quiz != null ?
                  <Quiz /> : <PDFViewer selectedPDF={state.pdf} />)
          }
        </div>
      </>
    ) : error !== undefined ?
      <div className={styles.playlist_container}>
        {error}
      </div> :
      <Spinner />
  );
};

export default Playlist;
